












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  getCalls
} from '@/api/calls'
import {
  createFilter,
  FilterOperator,
  FilterType,
  prepareListFilters
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import {
  parseTime,
  getTranslations
} from '@/utils'

@Component({
  name: 'PropertyCallList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private listQuery = createFilter()
  private parseTime = parseTime
  private getTranslations = getTranslations

  created() {
    this.getList()
  }

  private getPhones(client: any) {
    const phones = []
    if (client.secondary_phone) {
      phones.push(client.secondary_phone)
    }
    if (client.main_phone) {
      phones.push(client.main_phone)
    }
    return phones.join(' - ')
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: '',
      searchFields: [],
      filters: [],
      callback: () => {
        this.listQuery.query?.filters?.items?.push({
          type: FilterType.field,
          key: 'property_id',
          value: this.property.id,
          operator: FilterOperator.eq
        })
      }
    })
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getCalls(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }
}
